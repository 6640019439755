import React, { Component } from "react";
import { HashRouter, Route } from "react-router-dom";
import { Subscribe } from "./subscribe";
import { Story } from "./story";
import { ThankYou } from "./thankyou";
import "../styles/app.css";

export class App extends Component {
  public render(): JSX.Element {
    return (
      <HashRouter>
        <Route exact path="/" component={Subscribe} />
        <Route exact path="/thankyou" component={ThankYou} />
        <Route exact path="/story" component={Story} />
      </HashRouter>
    );
  }
}
