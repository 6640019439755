import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Theme, withStyles } from "@material-ui/core/styles";
import jsonp from "jsonp";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { SQUEEZE_BUTTON } from "../constants";
import { getMailchimpStyles } from "../styles/themes";
import { useWindowDimensions } from "./useWindowDimensions";

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#FAF5F5",
    border: "none",
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "#9D603F",
    "&:hover": {
      backgroundColor: "#9D603F",
    },
    "&:disabled": {
      color: "#FAF5F5",
      backgroundColor: "#161515",
    },
    flexGrow: 1,
    fontFamily: "Open Sans",
    height: 40,
  },
}))(Button);

export function MailChimp(): JSX.Element {
  const [userEmail, setEmail] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const props = { windowHeight: height, windowWidth: width };
  const classes = getMailchimpStyles(props);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const action =
      "https://konmini.us5.list-manage.com/subscribe/post?u=b75c9939f4866a5e98e949234&id=724e844512";
    const values = `EMAIL=${encodeURIComponent(userEmail)}`;
    const path = `${action}&${values}`;
    const url = path.replace("/post?", "/post-json?");
    jsonp(url, { param: "c" }, (err, data) => {
      history.push("/thankyou");
    });
  };

  const handleInputChange = (event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (
      value &&
      value.length > 0 &&
      value.indexOf("@") > 0 &&
      value.indexOf(".") > 0
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
    setEmail(value);
  };

  return (
    <form
      className={classes.signupContainer}
      onSubmit={(event) => handleSubmit(event)}
    >
      <TextField
        id="email"
        label="enter email"
        variant="outlined"
        type="email"
        name="email"
        autoComplete="email"
        size="small"
        className={classes.emailInput}
        required
        value={userEmail}
        onChange={(event) => handleInputChange(event)}
      />
      <ColorButton
        variant="contained"
        color="primary"
        disabled={btnDisabled}
        onClick={(event) => handleSubmit(event)}
      >
        {SQUEEZE_BUTTON}
      </ColorButton>
    </form>
  );
}
