import { makeStyles } from "@material-ui/styles";
import SubscribeBackgroundImg from "../assets/subscribe.png";
import StoryBackgroundImg from "../assets/story.jpg";
import ThanksBackgroundImg from "../assets/thanks.png";

export interface StyleProps {
  windowHeight: number;
  windowWidth: number;
}

export const getSubscribeStyles = makeStyles({
  container: {
    backgroundImage: `url(${SubscribeBackgroundImg})`,
    backgroundPosition: "bottom right",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  headerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  contentContainer: {
    display: "flex",
    flex: 9,
    justifyContent: "center",
    alignItems: "center",
  },
  blurredContainer: (props: StyleProps) => ({
    background: "rgba(255,255,255,.2)",
    boxShadow: "0 5px 15px rgba(0,0,0,.5)",
    width: props.windowWidth < 450 ? 350 : 400,
    height: props.windowWidth < 300 ? 350 : props.windowWidth < 350 ? 300 : 250,
    padding: "5%",
  }),
  title: {
    color: "#161515",
    fontFamily: "Oswald",
    fontSize: 35,
    letterSpacing: 3,
    marginBottom: 0,
    textAlign: "center",
  },
  signupInstructions: {
    fontFamily: "Open Sans",
    fontSize: 16,
    marginTop: 30,
    textAlign: "center",
  },
});

export const getThankYouStyles = makeStyles({
  container: {
    backgroundImage: `url(${ThanksBackgroundImg})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  headerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  contentContainer: (props: StyleProps) => ({
    display: "flex",
    flex: 9,
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "left",
    paddingTop: "2%",
    paddingLeft: "5%",
    paddingRight: props.windowWidth < 830 ? "5%" : "50%",
  }),
  title: (props: StyleProps) => ({
    color: "#161515",
    fontFamily: "Oswald",
    fontSize:
      props.windowWidth < 330 || props.windowHeight < 420
        ? 25
        : props.windowWidth < 420
        ? 30
        : 45,
    letterSpacing: 3,
    marginBottom: 0,
  }),
  signature: {
    marginTop: 30,
  },
  subtitle: (props: StyleProps) => ({
    color: "#161515",
    fontFamily: "Open Sans",
    fontSize: props.windowWidth < 330 || props.windowHeight < 420 ? 16 : 17,
    letterSpacing: 1,
    marginTop: 30,
    marginBottom: 5,
  }),
  text: (props: StyleProps) => ({
    color: "#161515",
    fontFamily: "Open Sans",
    fontSize: props.windowWidth < 330 || props.windowHeight < 420 ? 12 : 14,
    letterSpacing: 2,
  }),
});

export const getStoryStyles = makeStyles({
  container: {
    backgroundImage: `url(${StoryBackgroundImg})`,
    backgroundPosition: "top center",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  headerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  contentContainer: {
    display: "flex",
    flex: 9,
    justifyContent: "center",
    alignItems: "center",
  },
  blurredContainer: (props: StyleProps) => ({
    background: "rgba(0,0,0,.3)",
    boxShadow: "0 5px 15px rgba(0,0,0,.5)",
    width: props.windowWidth < 600 ? "90%" : "65%",
    height: props.windowWidth < 600 ? "90%" : "65%",
    padding: "4%",
  }),
  storyText: (props: StyleProps) => ({
    color: "#FAF5F5",
    fontFamily: "Open Sans",
    fontSize:
      props.windowWidth < 325
        ? 10
        : props.windowWidth < 380 && props.windowHeight < 700
        ? 12
        : props.windowWidth < 500
        ? 14
        : 16,
    letterSpacing: 3,
    lineHeight: "150%",
  }),
  storyTextCentered: {
    textAlign: "center",
  },
});

export const getLogoStyles = makeStyles({
  logoContainer: {
    flex: 1,
  },
  konminiLogo: {
    border: 0,
    marginLeft: 10,
    width: 70,
  },
});

export const getHeaderStyles = makeStyles({
  socialContainer: {
    flex: 1,
  },
  social: {
    border: 0,
    display: "inline-block",
    float: "right",
    height: 25,
    marginRight: "4%",
    marginTop: 20,
    verticalAlign: "middle",
    width: 25,
  },
  navDark: {
    color: "#161515",
    fontFamily: "Open Sans",
    fontSize: 14,
    textDecoration: "none",
  },
  navLight: {
    color: "#FAF5F5",
    fontFamily: "Open Sans",
    fontSize: 14,
    textDecoration: "none",
  },
  navDesktop: {
    border: 0,
    display: "inline-block",
    float: "right",
    height: 25,
    marginRight: "4%",
    marginTop: 23,
    verticalAlign: "middle",
    width: 35,
  },
});

export const getMailchimpStyles = makeStyles({
  signupContainer: (props: StyleProps) => ({
    display: "flex",
    marginTop: 30,
    height: 40,
    width: "100%",
  }),
  emailInput: {
    backgroundColor: "#FAF5F5",
    flexGrow: 9,
    fontFamily: "Open Sans",
    height: 40,
  },
});
