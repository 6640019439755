import React from "react";
import { Link } from "react-router-dom";
import KonminiLogo from "../assets/konminiLogo.svg";
import { getLogoStyles } from "../styles/themes";

export function Logo(): JSX.Element {
  const classes = getLogoStyles();
  return (
    <div className={classes.logoContainer}>
      <Link to="/">
        <img
          className={classes.konminiLogo}
          src={KonminiLogo}
          alt="Konmini Logo"
        />
      </Link>
    </div>
  );
}
