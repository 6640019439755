import ReactFullpage from "@fullpage/react-fullpage";
import React from "react";
import { Helmet } from "react-helmet";
import {
  LANDSCAPE_NOT_SUPPORTED,
  MINIMUM_SCREEN_HEIGHT_LANDSCAPE,
  PAGE_TITLE_STORY,
  SITE_DESCRIPTION,
  STORY_SECTION1,
  STORY_SECTION2,
  STORY_SECTION3,
} from "../constants";
import { getStoryStyles } from "../styles/themes";
import { Logo } from "./logo";
import { Social } from "./social";
import { useWindowDimensions } from "./useWindowDimensions";

export function Story(): JSX.Element {
  const { height, width } = useWindowDimensions();
  const isLandscapeMode = height < MINIMUM_SCREEN_HEIGHT_LANDSCAPE;
  const props = { windowHeight: height, windowWidth: width };
  const classes = getStoryStyles(props);
  return (
    <ReactFullpage
      licenseKey={"ABC123"}
      scrollingSpeed={1000}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <div className={classes.container}>
                <Helmet>
                  <title>{PAGE_TITLE_STORY}</title>
                  <meta name="description" content={SITE_DESCRIPTION} />
                </Helmet>
                <div className={classes.headerContainer}>
                  <Logo />
                  <Social isDarkBarkground={true} />
                </div>
                <div className={classes.contentContainer}>
                  <div className={classes.blurredContainer}>
                    <p hidden={isLandscapeMode} className={classes.storyText}>
                      {STORY_SECTION1}
                    </p>
                    <p hidden={isLandscapeMode} className={classes.storyText}>
                      {STORY_SECTION2}
                    </p>
                    <p hidden={isLandscapeMode} className={classes.storyText}>
                      {STORY_SECTION3}
                    </p>
                    <p
                      hidden={!isLandscapeMode}
                      className={[
                        classes.storyText,
                        classes.storyTextCentered,
                      ].join(" ")}
                    >
                      {LANDSCAPE_NOT_SUPPORTED}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}
