import React from "react";
import { Link } from "react-router-dom";
import InstagramWhite from "../assets/instagramWhite.svg";
import InstagramBlack from "../assets/instagramBlack.svg";
import TwitterBlack from "../assets/twitterBlack.svg";
import TwitterWhite from "../assets/twitterWhite.svg";
import { getHeaderStyles } from "../styles/themes";

interface SocialProps {
  isDarkBarkground: boolean;
}

export function Social(props: SocialProps): JSX.Element {
  const classes = getHeaderStyles();
  return (
    <div className={classes.socialContainer}>
      <a
        href="https://www.instagram.com/eatkonmini"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={classes.social}
          src={props.isDarkBarkground ? InstagramWhite : InstagramBlack}
          alt="Instagram"
        />
      </a>
      <a
        href="https://twitter.com/eatkonmini"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={classes.social}
          src={props.isDarkBarkground ? TwitterWhite : TwitterBlack}
          alt="Twitter"
        />
      </a>
      <div className={classes.navDesktop}>
        <Link
          to="/story"
          className={
            props.isDarkBarkground ? classes.navLight : classes.navDark
          }
        >
          Story
        </Link>
      </div>
    </div>
  );
}
