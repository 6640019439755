export const SITE_DESCRIPTION =
  "Bringing bite-sized Japanese culture to Colorado. Check out our Japanese sandwiches.";
export const PAGE_TITLE_HOME = "Konmini";
export const PAGE_TITLE_THANKS = "Thank You";
export const PAGE_TITLE_STORY = "Our Story";

export const SQUEEZE_PAGE_TITLE = "KONMINI IS COMING";
export const SQUEEZE_PAGE_DESCRIPTION =
  "We are bringing quality Japanese sandwiches to Colorado. Join us to stay tuned!";
export const SQUEEZE_BUTTON = "TRY IT";

export const STORY_SECTION1 =
  "Janita and Jonathan met in San Francisco volunteering at a soup kitchen, and we have since shared a culinary journey locally and internationally. We fell in love with Japanese sandwiches during our first trip to Japan. We wanted to share those memories and taste profiles, so we developed our bread recipe for months until it was as how we remember the shokupan (Japanese white bread) in Japan.";
export const STORY_SECTION2 =
  "Konmini produces Japanese inspired sandwiches with our shokupan as the centerpiece. Each bite may awaken hidden childhood memories of eating homemade sandwiches at a school cafeteria or memories of picnics with friends & family, or - like us - memories of traveling and adventuring together with your loved ones.";
export const STORY_SECTION3 =
  "With our select ingredients and lack of preservatives, we hope to share a bite of Japanese culture from our kitchen to yours.";

export const TY_PAGE_TITLE = "ARIGATOU GOZAIMASU !";
export const TY_PAGE_TITLE_DESCRIPTION =
  "(IT'S HOW WE SAY THANK YOU IN JAPANESE)";
export const TY_PAGE_SUBTITLE1 = "Here's what to expect";
export const TY_PAGE_SUBTITLE1_DESCRIPTION =
  "We will email an update when and where we launch.";
export const TY_PAGE_SUBTITLE2 = "The bigger picture";
export const TY_PAGE_SUBTITLE2_DESCRIPTION =
  "We love good food and we want to share it. Our mission is to deliver quality and convenient Japanese food with warm and efficient service.";
export const TY_PAGE_SHARE_US =
  "Want to share the love? Follow us on social media!";
export const TY_PAGE_WELCOME = "Welcome aboard";
export const TY_PAGE_SIGNATURE = "-- The Konmini Crew";

export const MINIMUM_SCREEN_HEIGHT_LANDSCAPE = 550;
export const LANDSCAPE_NOT_SUPPORTED =
  "Oops, looks like your device is in landscape mode. Switch back to portrait mode or try viewing this page on a desktop.";
