import ReactFullpage from "@fullpage/react-fullpage";
import React from "react";
import { Helmet } from "react-helmet";
import {
  MINIMUM_SCREEN_HEIGHT_LANDSCAPE,
  PAGE_TITLE_THANKS,
  SITE_DESCRIPTION,
  TY_PAGE_TITLE,
  TY_PAGE_TITLE_DESCRIPTION,
  TY_PAGE_SUBTITLE1,
  TY_PAGE_SUBTITLE1_DESCRIPTION,
  TY_PAGE_SUBTITLE2,
  TY_PAGE_SUBTITLE2_DESCRIPTION,
  TY_PAGE_SHARE_US,
  TY_PAGE_WELCOME,
  TY_PAGE_SIGNATURE,
} from "../constants";
import { getThankYouStyles } from "../styles/themes";
import { Logo } from "./logo";
import { Social } from "./social";
import { useWindowDimensions } from "./useWindowDimensions";

export function ThankYou(): JSX.Element {
  const { height, width } = useWindowDimensions();
  const isLandscapeMode = height < MINIMUM_SCREEN_HEIGHT_LANDSCAPE;
  const props = { windowHeight: height, windowWidth: width };
  const classes = getThankYouStyles(props);
  return (
    <ReactFullpage
      licenseKey={"ABC123"}
      scrollingSpeed={1000}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <div className={classes.container}>
                <Helmet>
                  <title>{PAGE_TITLE_THANKS}</title>
                  <meta name="description" content={SITE_DESCRIPTION} />
                </Helmet>
                <div className={classes.headerContainer}>
                  <Logo />
                  <Social isDarkBarkground={false} />
                </div>
                <div className={classes.contentContainer}>
                  <h1 className={classes.title}>{TY_PAGE_TITLE}</h1>
                  <div className={classes.text}>
                    {TY_PAGE_TITLE_DESCRIPTION}
                  </div>
                  <div className={classes.subtitle}>{TY_PAGE_SUBTITLE1}</div>
                  <div className={classes.text}>
                    {TY_PAGE_SUBTITLE1_DESCRIPTION}
                  </div>
                  <div hidden={isLandscapeMode} className={classes.subtitle}>
                    {TY_PAGE_SUBTITLE2}
                  </div>
                  <div hidden={isLandscapeMode} className={classes.text}>
                    {TY_PAGE_SUBTITLE2_DESCRIPTION}
                  </div>
                  <div hidden={isLandscapeMode} className={classes.subtitle}>
                    {TY_PAGE_SHARE_US}
                  </div>
                  <div className={classes.signature}>
                    <div className={classes.text}>{TY_PAGE_WELCOME}</div>
                    <div className={classes.text}>{TY_PAGE_SIGNATURE}</div>
                  </div>
                </div>
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}
