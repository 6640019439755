import ReactFullpage from "@fullpage/react-fullpage";
import React from "react";
import { Helmet } from "react-helmet";
import {
  PAGE_TITLE_HOME,
  SITE_DESCRIPTION,
  SQUEEZE_PAGE_TITLE,
  SQUEEZE_PAGE_DESCRIPTION,
} from "../constants";
import { getSubscribeStyles } from "../styles/themes";
import { Logo } from "./logo";
import { MailChimp } from "./mailchimp";
import { Social } from "./social";
import { useWindowDimensions } from "./useWindowDimensions";

export function Subscribe(): JSX.Element {
  const { height, width } = useWindowDimensions();
  const props = { windowHeight: height, windowWidth: width };
  const classes = getSubscribeStyles(props);

  return (
    <ReactFullpage
      licenseKey={"ABC123"}
      scrollingSpeed={1000}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <div className={classes.container}>
                <Helmet>
                  <title>{PAGE_TITLE_HOME}</title>
                  <meta name="description" content={SITE_DESCRIPTION} />
                </Helmet>
                <div className={classes.headerContainer}>
                  <Logo />
                  <Social isDarkBarkground={false} />
                </div>
                <div className={classes.contentContainer}>
                  <div className={classes.blurredContainer}>
                    <h1 className={classes.title}>{SQUEEZE_PAGE_TITLE}</h1>
                    <div className={classes.signupInstructions}>
                      {SQUEEZE_PAGE_DESCRIPTION}
                    </div>
                    <MailChimp />
                  </div>
                </div>
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}
